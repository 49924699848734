import { Skeleton, Space, Typography } from '@ui';
import { selectGlobalLibrary, selectGlobalStudy } from '@app/duck/appSelectors';
import { css, CSSObject, Theme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { APP_HEADER_CUSTOM_TOOLBAR, APP_HEADER_ID, APP_HEADER_LEFT_ID } from './AppHeaderConstants';

const AppContextTitle = ({ title }: { title?: string }) => {
  if (!title) return <Skeleton active css={cssAppContextTitle} paragraph={{ rows: 1, width: 150 }} title={false} />;

  return <Typography.Text type="secondary" children={title} />;
};

export const AppHeader = () => {
  const globalStudy = useSelector(selectGlobalStudy);
  const globalLibrary = useSelector(selectGlobalLibrary);

  return (
    <Space css={cssBody} full justify="space-between">
      <Space>
        {globalStudy && <AppContextTitle title={globalStudy?.name} />}
        {globalLibrary && <AppContextTitle title={globalLibrary?.name} />}
        <div css={cssHeaderLeft} id={APP_HEADER_LEFT_ID}></div>
      </Space>
      <Space css={cssHeaderRight}>
        <div css={cssCustomToolbar} id={APP_HEADER_CUSTOM_TOOLBAR} />
        <div css={cssCustomToolbarButtons} id={APP_HEADER_ID}></div>
      </Space>
    </Space>
  );
};

const cssBody = (theme: Theme): CSSObject => ({
  padding: `0 ${theme.paddingLG}px`,
  backgroundColor: theme.colorBgBase,
  height: '100%',
  borderBottom: `1px solid ${theme.colorBorder}`,
  textWrap: 'nowrap',
});

const cssHeaderLeft = (): CSSObject => ({
  display: 'flex',
  gap: 8,
});

const cssHeaderRight = (): CSSObject => ({
  gap: 24,
});

const cssAppContextTitle = css({
  '& .ant-skeleton-paragraph': {
    marginBottom: 0,
  },
});

const cssCustomToolbar = css({
  display: 'flex',
  gap: 24,
});

const cssCustomToolbarButtons = (): CSSObject => ({
  display: 'flex',
  alignItems: 'center',
  gap: 24,

  '&& > span, > div': {
    width: 42,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});
