import { Button, Dropdown, Skeleton, Space } from '@ui';
import { ConfirmModal, useConfirmModal } from '@components';
import { ChevronDownIcon } from '@components/icons';
import { DropdownProps } from 'antd';
import { CSSObject, Theme } from '@emotion/react';
import { ReactNode, useState } from 'react';

export const MoreButton = ({ label, menu, loading, deleteConfig, withIconRotate, ...props }: MoreButtonProps) => {
  const confirmModal = useConfirmModal();

  const [open, setOpen] = useState(false);

  const deleteMenuItem = {
    key: 'delete',
    label: deleteConfig?.itemLabel ?? 'Delete',
    disabled: deleteConfig?.disabled ?? false,
    danger: deleteConfig?.danger ?? true,
  };
  const menuItems = menu?.items ?? [];

  const onClickHandle = async (info: any) => {
    const { key } = info;
    if (menu?.onClick) {
      await menu.onClick({ ...info, key });
    }

    if (key === 'delete') {
      confirmModal.openConfirmation({
        content: deleteConfig?.confirmContent,
      });
    }
  };

  const preparedMenu = {
    ...menu,
    items: deleteConfig ? [...menuItems, deleteMenuItem] : menuItems,
    onClick: onClickHandle,
    style: menu?.style ?? { width: 180 },
  };

  return loading ? (
    <Skeleton.Button active />
  ) : (
    <>
      <Dropdown
        menu={preparedMenu}
        trigger={['click']}
        placement="bottomRight"
        autoAdjustOverflow={false}
        onOpenChange={setOpen}
        {...props}
      >
        <Button
          size="large"
          type="link"
          css={cssActionBtn}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Space>
            {label ?? 'More'}
            <ChevronDownIcon css={cssSwitcherIcon(open, withIconRotate)} />
          </Space>
        </Button>
      </Dropdown>

      {deleteConfig && (
        <ConfirmModal
          title={deleteConfig.confirmTitle}
          submitFunc={deleteConfig.submitFunc}
          okText={deleteConfig?.confirmOkText ?? 'Delete'}
          okButtonProps={{ danger: true }}
          {...confirmModal}
        />
      )}
    </>
  );
};

interface MoreButtonProps extends DropdownProps {
  menu: DropdownProps['menu'];
  loading?: boolean;
  label?: string;
  deleteConfig?: {
    itemLabel?: string;
    disabled?: boolean;
    danger?: boolean;
    submitFunc: () => void;
    confirmTitle: string;
    confirmContent: ReactNode;
    confirmOkText?: string;
  };
  withIconRotate?: boolean;
}

const cssActionBtn = (theme: Theme): CSSObject => ({
  padding: 0,
});

const cssSwitcherIcon = (expanded: boolean, withIconRotate?: boolean) => (): CSSObject => ({
  color: 'inherit',
  fontWeight: 500,
  fontSize: 24,
  ...(withIconRotate && { transition: '0.3s all' }),
  ...(withIconRotate && { transform: expanded ? 'rotate(180deg)' : undefined }),
});
