import { Button, Checkbox, Dropdown, Space, Spin, Tooltip, Typography } from '@ui';
import { DoubleCheck } from '@components/icons';
import { MoreButton } from '@components';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined, LoadingOutlined, MoreOutlined } from '@ant-design/icons';
import { CSSObject, Theme } from '@emotion/react';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useState } from 'react';
import dayjs from 'dayjs';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { MenuProps } from 'antd/es/menu';
import { useMessage } from '../duck/hooks/messages';
import {
  TNotificationId,
  useArchiveMutation,
  useReadMutation,
  TNotification,
  useUnreadMutation,
} from '../duck/notificationsApi';
import { fetchNotifications } from '../duck/notificationsListeners';

export const NotificationItem = ({ notification, selectable, selected, onSelect }: INotificationItem) => {
  const { t } = useTranslation(['shared']);
  const [read] = useReadMutation();
  const [unread] = useUnreadMutation();
  const [archive] = useArchiveMutation();
  const [processing, setProcessing] = useState(false);
  const { composeMessage, composeLink, composeActions } = useMessage();
  const message = composeMessage(notification.extra);
  const link = composeLink(notification.extra);
  const actions = composeActions(notification.extra)?.filter((action) => action.title) ?? [];

  const downloadActions = actions.filter((action) => action.isDownload);
  const ordinaryActions = actions.filter((action) => !action.isDownload) ?? [];

  const archiveNotification = async () => {
    if (processing) {
      return;
    }

    setProcessing(true);
    try {
      await archive([parseInt(notification.notificationId)]).unwrap();
      fetchNotifications();
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
    }
  };

  const readNotification = async () => {
    if (notification.isRead) {
      return;
    }

    setProcessing(true);
    try {
      await read([parseInt(notification.notificationId)]).unwrap();
      fetchNotifications();
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
    }
  };

  const unreadNotification = async () => {
    if (!notification.isRead) {
      return;
    }

    setProcessing(true);
    try {
      await unread([parseInt(notification.notificationId)]).unwrap();
      fetchNotifications();
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
    }
  };

  const handleSelect = (e: CheckboxChangeEvent) => {
    if (onSelect) {
      onSelect(notification.notificationId);
    }
  };

  const overflowMenuItems = [
    ...(link
      ? [{ key: 'info', label: t('notification.info'), icon: <InfoCircleOutlined css={cssActionMenuIcons} /> }]
      : []),
    {
      key: 'markRead',
      label: t('notification.markRead'),
      icon: <DoubleCheck css={cssActionMenuIcons} />,
    },
    { key: 'markUnRead', label: t('notification.markUnRead'), icon: <CheckOutlined css={cssActionMenuIcons} /> },
    { key: 'archive', label: t('notification.archive'), icon: <CloseOutlined css={cssActionMenuIcons} /> },
  ].filter((item) => typeof item === 'object') as MenuProps['items'];

  const preparedOverflowMenu = {
    items: overflowMenuItems,
    onClick: async ({ key }: { key: string }) => {
      switch (key) {
        case 'info':
          // we need to use full restart page (reloadDocument props in Link)
          window.location.href = link;
          break;
        case 'markRead':
          readNotification();
          break;
        case 'markUnRead':
          unreadNotification();
          break;
        case 'archive':
          archiveNotification();
          break;
        default:
          break;
      }
    },
  };

  const renderDownloadLinks = () => {
    if (downloadActions.length === 1) {
      const { id, onClick, icon, fileName } = downloadActions[0];
      return (
        <Button key={id ?? 'action'} css={cssAction} size="large" type="link" icon={icon} onClick={onClick}>
          {fileName ? t('notification.downloadFile', { fileName }) : t('notification.downloadFile')}
        </Button>
      );
    }

    const downloadLinks = downloadActions.map((action) => ({
      key: action.fileName ?? 'N/A',
      label: `Download ${action.fileName} report`,
      icon: <div css={cssOverflowMenuIcon}>{action.icon}</div>,
      onClick: action.onClick,
    }));

    const onMenuItemsHandler: MenuProps['onClick'] = ({ key }) => {
      const findAction = downloadActions?.find((el) => el.fileName === key);
      findAction?.onClick();
    };

    return (
      <MoreButton
        css={cssDownloadContainer}
        label={t('notification.downloadLinks')}
        menu={{
          items: downloadLinks,
          onClick: onMenuItemsHandler,
          style: menuStyle,
        }}
        placement="bottomRight"
        withIconRotate
      />
    );
  };

  return (
    <div css={cssItem(message.error, notification.isRead)} onClick={readNotification}>
      <div css={cssContent}>
        <Space full direction="horizontal">
          {selectable && <Checkbox checked={!!selected} onChange={handleSelect} onClick={(e) => e.stopPropagation()} />}
          <span css={cssReadDot(notification.isRead)} />
          <span css={cssActor}>{startCase(notification.actorName ?? 'Unknown')}</span>
          <Typography.Text type="secondary" css={{ fontSize: 14 }}>
            <Tooltip placement="top" title={dayjs(Number(notification.creationDateTs)).toString()}>
              &#x2022; {dayjs(Number(notification.creationDateTs)).fromNow()}
            </Tooltip>
          </Typography.Text>
        </Space>
        <div css={cssMessage(selectable)}>
          <span>{message.text}</span>
        </div>
        {ordinaryActions &&
          ordinaryActions.map(({ id, onClick, icon, title }) => (
            <Button key={id ?? 'action'} css={cssAction} size="large" type="link" icon={icon} onClick={onClick}>
              {title}
            </Button>
          ))}
        {!!downloadActions?.length && renderDownloadLinks()}
      </div>
      <div>
        {!notification.isArchived ? (
          <>
            {processing && <Spin indicator={<LoadingOutlined />} css={cssSpin} />}
            {!processing && (
              <Dropdown
                className="overflowMenu"
                menu={preparedOverflowMenu}
                trigger={['click']}
                placement="bottomRight"
                autoAdjustOverflow={false}
                overlayStyle={{ minWidth: 200 }}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  css={cssActionBtn}
                >
                  <MoreOutlined css={cssOverflowMenuIcon} />
                </button>
              </Dropdown>
            )}
          </>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </div>
  );
};

interface INotificationItem {
  notification: TNotification;
  selectable: boolean;
  selected?: boolean;
  onSelect?: (id: TNotificationId) => void;
}

const cssItem =
  (error: boolean, isRead: boolean = true) =>
  (theme: Theme): CSSObject => ({
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '0.5rem',
    padding: '15px 16px 5px',
    borderBottom: '1px solid #d4d4d4',
    backgroundColor: error ? '#ffe9e9' : !isRead ? theme['color-brand-blue-100'] : 'transparent',
    '&:hover': {
      backgroundColor: error ? '#ffbbbb' : 'white',
      '&& .ant-dropdown-trigger.overflowMenu ': {
        visibility: 'visible',
      },
    },

    '&& .ant-dropdown-trigger.overflowMenu': {
      visibility: 'hidden',
    },
  });

const cssReadDot =
  (isRead: boolean = true) =>
  (theme: Theme): CSSObject => ({
    visibility: !isRead ? 'visible' : 'hidden',
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme['color-brand-blue-500'],
  });

const cssContent = (): CSSObject => ({
  width: '100%',
});

const cssActor = (): CSSObject => ({
  fontWeight: 500,
});

const cssMessage = (selectable?: boolean): CSSObject => ({
  overflowWrap: 'anywhere',
  marginLeft: selectable ? 40 : 16,
});

const cssAction = (): CSSObject => ({
  marginLeft: 16,
  marginTop: 8,

  '&& .ant-btn-icon > svg': {
    color: 'inherit',
  },
});

const cssActionBtn = (theme: Theme): CSSObject => ({
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  padding: 0,
  marginLeft: 8,
  color: theme['color-grey-600'],
});

const cssSpin = (): CSSObject => ({
  fontSize: 24,
  marginLeft: 8,
});

const cssActionMenuIcons = (theme: Theme): CSSObject => ({
  color: theme['color-grey-600'],
  marginRight: 8,
  fontSize: 14,
  width: 24,
  height: 24,
  '& > svg': {
    width: 24,
    fontSize: 14,
  },
});

const cssOverflowMenuIcon = (): CSSObject => ({
  fontSize: 24,

  '&&.ant-dropdown-menu-item-icon': {
    fontSize: 24,
  },
});

const cssDownloadContainer = (): CSSObject => ({
  marginTop: 8,
  marginLeft: 40,
});

const menuStyle: CSSProperties = {
  minWidth: 300,
  maxHeight: 300,
  overflowY: 'auto',
};
