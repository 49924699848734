import { Button, Popover, Space, Tag, Typography } from '@ui';
import { dateToString } from '@shared/utils/Date';
import { isJsonString } from '@modules/library/analysisPackage/duck/analysisPackageUtils';
import { EDeploymentObjectType, ELibraryEntityNames } from '@modules/library/root/LibraryTypes';
import { QuestionMark } from '@components/icons';
import Scrollbars from 'react-custom-scrollbars-2';
import { CSSObject } from '@emotion/react';
import { useTranslation } from 'react-i18next';

export const LogsInfo = ({ logs }: { logs?: string }) => {
  const { t } = useTranslation(['analysisPackage']);

  if (!logs) {
    return (
      <Typography.Paragraph>
        <pre>{t('deployments.noLogData')}</pre>
      </Typography.Paragraph>
    );
  }

  if (!isJsonString(logs)) {
    return (
      <Typography.Paragraph>
        <pre>{logs}</pre>
      </Typography.Paragraph>
    );
  }

  const logsParsed = JSON.parse(logs);

  return (
    <Typography.Paragraph>
      <pre>
        <Space direction="vertical" css={{ gap: 24 }}>
          <Space direction="vertical">
            <Space>
              <Typography.Text>{t('deployments.started_at')}:</Typography.Text>
              <Typography.Text type="secondary">
                {logsParsed.started_at ? dateToString(logsParsed.started_at, true) : '-'}
              </Typography.Text>
            </Space>
            <Space>
              <Typography.Text>{t('deployments.finished_at')}:</Typography.Text>
              <Typography.Text type="secondary">
                {logsParsed.finished_at ? dateToString(logsParsed.finished_at, true) : '-'}
              </Typography.Text>
            </Space>
          </Space>
          <Scrollbars css={cssLogsLayout} autoHide={false} autoHeight autoHeightMin={500}>
            {Object.entries(EDeploymentObjectType).map(([key, value]) => {
              const categoryName = ELibraryEntityNames[key as keyof typeof ELibraryEntityNames];
              return (
                logsParsed[value] && (
                  <Space key={value} direction="vertical" full css={{ gap: 8, paddingBottom: 12 }}>
                    <Typography.Title level={5}>{categoryName}</Typography.Title>
                    <Space direction="vertical" full css={{ gap: 4 }}>
                      {Object.values(logsParsed[value]).map((item: any) => (
                        <Space key={`${value}_${item?.name}`} full css={cssObjectItemsContainer}>
                          <Typography.Text>{item?.name}:</Typography.Text>
                          <Tag type={item?.status?.toLowerCase()}>{item?.status}</Tag>
                          {item?.error && (
                            <Popover
                              content={item?.error}
                              placement="right"
                              overlayStyle={{ width: '400px', wordWrap: 'break-word' }}
                            >
                              <Button css={cssErrorButton} type="text" icon={<QuestionMark css={cssIcon} />} />
                            </Popover>
                          )}
                        </Space>
                      ))}
                    </Space>
                  </Space>
                )
              );
            })}
          </Scrollbars>
        </Space>
      </pre>
    </Typography.Paragraph>
  );
};

const cssLogsLayout = (): CSSObject => ({
  height: 500,
});

const cssObjectItemsContainer = (): CSSObject => ({
  paddingLeft: 24,
  fontSize: 14,
  gap: 4,
});

const cssErrorButton = (): CSSObject => ({
  '&&': {
    width: 'auto',

    '&&.ant-btn-icon-only': {
      padding: 0,
    },
  },
});

const cssIcon = (): CSSObject => ({
  fontSize: 20,
  cursor: 'pointer',
});
