import { Checkbox, DatePicker, FormItem, Input, Radio, Select, Space, Switch, TimePicker } from '@ui';
import { RangeNumberPicker, RUN_TYPE, TRunPeriodically } from '@components/form';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { CSSObject, Theme } from '@emotion/react';
import dayjs from 'dayjs';
import {
  PERIODICALLY_EVERY_OPTIONS,
  PERIODICALLY_HOUR_OPTIONS,
  PERIODICALLY_MONTHS,
  PERIODICALLY_WEEKDAYS,
} from './schedulerRunFormConstants';

import { changePartionallyFields, getDaysInMonths } from './schedulerRunFormUtils';

interface ICustomInputProps<Values = any> {
  value?: Values;
  disabled?: boolean;
  onChange?: (value: any) => void;
  t: TFunction;
}

interface SchedulerRunFormProps {
  isDisabledProp: boolean;
  hideAutomaticOptions?: boolean;
  renderActiveField?: boolean;
  showShedulerLabel?: boolean;
  isStackDataset?: boolean;
}

export const initPeriodicallyFields = changePartionallyFields({} as any, {
  name: 'every',
  value: 0,
});

export const PeriodicallyFields = ({ value, disabled, onChange, t }: ICustomInputProps<TRunPeriodically>) => {
  const triggerChange = (field: { name: string; value: any }) => {
    onChange?.(changePartionallyFields(value!, field));
  };

  return (
    <div style={{ marginBottom: '-24px' }}>
      <FormItem label={t('scheduler.every')} labelCol={{ span: 4 }}>
        <div style={{ display: 'flex', gap: '8px' }}>
          {value?.every === PERIODICALLY_EVERY_OPTIONS.hour.value && (
            <Select
              value={value?.hour}
              options={PERIODICALLY_HOUR_OPTIONS}
              disabled={disabled}
              onChange={(v) => triggerChange({ name: 'hour', value: v })}
              className="schedulerJobsEditor_hourField"
            />
          )}
          <Select
            value={value?.every}
            disabled={disabled}
            options={Object.values(PERIODICALLY_EVERY_OPTIONS)}
            onChange={(v) => triggerChange({ name: 'every', value: v })}
          />
        </div>
      </FormItem>
      {value?.every === PERIODICALLY_EVERY_OPTIONS.year.value && (
        <FormItem label="In:" labelCol={{ span: 4 }}>
          <Select
            placeholder={t('scheduler.everyMonth')}
            mode="multiple"
            value={value?.inMonth}
            options={PERIODICALLY_MONTHS}
            disabled={disabled}
            onChange={(v) => triggerChange({ name: 'inMonth', value: v })}
            allowClear
          />
        </FormItem>
      )}
      {[PERIODICALLY_EVERY_OPTIONS.month.value, PERIODICALLY_EVERY_OPTIONS.year.value].includes(value!.every) && (
        <FormItem label="On:" labelCol={{ span: 4 }}>
          <RangeNumberPicker
            placeholder={t('scheduler.everyDayOfTheMonth')}
            range={getDaysInMonths(value?.inMonth || [])}
            value={value?.onDays}
            disabled={disabled}
            onChange={(v) => triggerChange({ name: 'onDays', value: v })}
          />
        </FormItem>
      )}
      <FormItem>
        <Checkbox.Group
          value={value?.weekdays}
          disabled={disabled}
          onChange={(v) => triggerChange({ name: 'weekdays', value: v })}
        >
          <Space size={[4, 8]} wrap>
            {PERIODICALLY_WEEKDAYS.map((d) => (
              <div style={{ minWidth: '80px' }} key={d.value}>
                <Checkbox value={d.value.toString()} disabled={disabled}>
                  {d.label}
                </Checkbox>
              </div>
            ))}
          </Space>
        </Checkbox.Group>
      </FormItem>
      {value?.every === PERIODICALLY_EVERY_OPTIONS.hour.value && (
        <Space full direction={'horizontal'}>
          <FormItem label={t('scheduler.from')} labelCol={{ span: 6 }}>
            <TimePicker
              size="large"
              popupStyle={{ color: 'red' }}
              css={cssTimePicker}
              format="h:mm A"
              value={value?.fromTime ? dayjs(value?.fromTime) : undefined}
              disabled={disabled}
              onChange={(v) => triggerChange({ name: 'fromTime', value: v?.valueOf() })}
              style={{ width: '100%' }}
            />
          </FormItem>
          <FormItem label={t('scheduler.to')} labelCol={{ span: 6 }}>
            <TimePicker
              size="large"
              css={cssTimePicker}
              format="h:mm A"
              value={value?.toTime ? dayjs(value?.toTime) : undefined}
              disabled={disabled}
              onChange={(v) => triggerChange({ name: 'toTime', value: v?.valueOf() })}
              style={{ width: '100%' }}
            />
          </FormItem>
        </Space>
      )}
      {value?.every !== PERIODICALLY_EVERY_OPTIONS.hour.value && (
        <FormItem label={t('scheduler.atTime')} labelCol={{ span: 4 }}>
          <TimePicker
            size="large"
            css={cssTimePicker}
            format="h:mm A"
            value={value?.atTime ? dayjs(value?.atTime) : undefined}
            disabled={disabled}
            onChange={(v) => triggerChange({ name: 'atTime', value: v?.valueOf() })}
          />
        </FormItem>
      )}
    </div>
  );
};
const cssTimePicker = (theme: Theme): CSSObject => ({
  color: theme['color-grey-900'],
  fontSize: 16,
  fontFamily: 'Rubik',
  fontWeight: 400,
  '&&': {
    '& .ant-picker-outlined': {
      backgroundColor: 'red',
    },
  },
});

const cssRangeTimeBox = (): CSSObject => ({});

export const SchedulerRunForm = ({
  isDisabledProp,
  hideAutomaticOptions,
  renderActiveField,
  showShedulerLabel,
  isStackDataset,
}: SchedulerRunFormProps) => {
  const { t } = useTranslation('datastore');

  const renderRunRadioGroup = () => {
    return (
      <Radio.Group>
        {!hideAutomaticOptions && <Radio value={RUN_TYPE.automatic}>{t('scheduler.automatic')}</Radio>}
        <Radio value={RUN_TYPE.once}>{isStackDataset ? t('scheduler.onceStackDataset') : t('scheduler.once')}</Radio>
        <Radio value={RUN_TYPE.periodically}>{t('scheduler.periodically')}</Radio>
      </Radio.Group>
    );
  };

  const renderRunOnceField = () => {
    if (isStackDataset) {
      return (
        <FormItem name={['job', 'runOnceAtStackDataset']}>
          <Input disabled />
        </FormItem>
      );
    }
    return (
      <FormItem
        name={['job', 'runOnceAt']}
        noStyle
        normalize={(value: any) => value?.valueOf()}
        getValueProps={(value) => ({
          value: value ? dayjs(value) : undefined,
        })}
        rules={[{ required: true, message: t('fieldRequired') }]}
      >
        <DatePicker
          style={{ width: '100%' }}
          showTime={{
            format: 'h:mm A',
          }}
          showSecond={false}
          showNow={false}
          format="MMM D, YYYY h:mm A"
        />
      </FormItem>
    );
  };

  return (
    <>
      {renderActiveField && (
        <FormItem
          css={{ height: 32 }}
          labelCol={{ span: 6 }}
          name={['job', 'active']}
          label={t('scheduler.active')}
          valuePropName="checked"
        >
          <Switch size="small" />
        </FormItem>
      )}
      <FormItem
        name={['job', 'run']}
        label={showShedulerLabel ? t('scheduler.schedulerLabel') : ''}
        labelCol={{ span: 6 }}
      >
        {renderRunRadioGroup()}
      </FormItem>
      <FormItem dependencies={[['job', 'run']]} asInfo>
        {(form) => {
          if (form.getFieldValue(['job', 'run']) === 'once') {
            return renderRunOnceField();
          }
          if (form.getFieldValue(['job', 'run']) === 'periodically') {
            return (
              <FormItem name={['job', 'runPeriodically']} initialValue={initPeriodicallyFields} asInfo>
                <PeriodicallyFields t={t} disabled={isDisabledProp} />
              </FormItem>
            );
          }
          return null;
        }}
      </FormItem>
    </>
  );
};
