import {
  useDatasetGlobalUsersQuery,
  useDatasetListPaginatedQuery,
  useDatasetStudyUsersQuery,
  useDeleteBulkDatasetMutation,
} from '@modules/dataset/duck/datasetApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { notification } from '@ui';
import { Trash } from '@components/icons';
import { ButtonWithConfirmation, PageTemplateSimple } from '@components';
import { DatasetList, DatasetConfirmationModalContent } from '@modules/dataset/components';
import { useTablePaginationState } from '@components/ui/table/tableHooks';
import { initialPage, isCrossStudy } from '@shared/utils/common';
import { IDataset } from '@modules/dataset/DatasetTypes';
import { SYSTEM_STORE_NAMES } from '@modules/stores/duck/storeConstants';
import { QueryErrorType } from '@shared/utils/Error';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const DatasetRootPage = () => {
  const { t } = useTranslation('dataset');
  const { paginationState, getPagination, onTableChange, preparedFilters, preparedSorter } =
    useTablePaginationState<IDataset>(initialPage);

  const [selectedTableItems, setSelectedTableItems] = useState<IDataset[]>([]);

  const globalStudy = useSelector(selectGlobalStudy);
  const isGlobal = isCrossStudy(globalStudy?.id!);

  const datasetQuery = useDatasetListPaginatedQuery({
    study_id: globalStudy?.id!,
    page: paginationState.current - 1,
    ...preparedFilters,
    ...preparedSorter,
  });

  const datasetStudyUsers = useDatasetStudyUsersQuery(undefined, { skip: isGlobal });
  const datasetGlobalUsers = useDatasetGlobalUsersQuery(undefined, { skip: !isGlobal });
  const studyUsers = datasetStudyUsers.data;
  const globalUsers = datasetGlobalUsers.data;

  const [deleteBulkDataset] = useDeleteBulkDatasetMutation();

  const pagination = getPagination(datasetQuery.data?.totalItems);

  const onHandleBulkDelete = async () => {
    const selectedItems = selectedTableItems.map((el) => el.id);

    if (selectedItems.length) {
      await deleteBulkDataset({ ids: selectedItems })
        .unwrap()
        .then(() => {
          notification.success({
            message: t('rootTable.actions.delete.successBulkDeleteMessage'),
          });
        })
        .catch((error: QueryErrorType) => {
          if (error.status === 524) {
            return notification.info({
              message: t('rootTable.actions.delete.longBulkDeleteMessage'),
            });
          }
          notification.error({
            message: t('shared.errors.smthWrong'),
            description: (error as QueryErrorType).data.userMsg,
          });
        })
        .finally(() => {
          setSelectedTableItems([]);
        });
    }
  };

  const hasSelected = selectedTableItems.length > 0;

  return (
    <PageTemplateSimple
      hideTitleSkeleton
      title={{
        children: t('pageRootName'),
        secondaryTitle: hasSelected ? `(Selected ${selectedTableItems.length} item(s))` : null,
        refetch: datasetQuery.refetch,
        extra: hasSelected && (
          <ButtonWithConfirmation
            height={300}
            btnText={t('rootTable.bulkDelete')}
            icon={<Trash />}
            danger
            size="large"
            submitFunc={onHandleBulkDelete}
            confirmTitle={t('rootTable.actions.delete.confirmationBulkDeleteTitle')}
            confirmContent={<DatasetConfirmationModalContent selectedTableItems={selectedTableItems} t={t} />}
          />
        ),
      }}
      content={{
        isLoading: datasetQuery.isLoading && !datasetQuery.data,
        errorText: t('loadingError'),
        error: datasetQuery.error,
      }}
    >
      {datasetQuery.data && (
        <DatasetList
          data={datasetQuery.data.items}
          onChange={onTableChange}
          pagination={pagination}
          loading={datasetQuery.isFetching}
          studyId={globalStudy?.id!}
          viewDatasetFolder={isGlobal ? SYSTEM_STORE_NAMES.GLOBAL_INTERNALS : SYSTEM_STORE_NAMES.INTERNALS}
          t={t}
          selectedTableItems={selectedTableItems}
          setSelectedTableItems={setSelectedTableItems}
          usersFilterList={studyUsers || globalUsers}
        />
      )}
    </PageTemplateSimple>
  );
};
