import { ReactNode } from 'react';

export type TMessage = {
  error: boolean;
  text: string | ReactNode;
};

export type TAction = {
  id?: string;
  title?: string;
  onClick: () => void;
  icon?: ReactNode;
  isDownload?: boolean;
  fileName?: string;
  url?: string;
};

export type TDatastageEvent = (TStoreEvent | TModelEvent | TExportEvent | TDeploymentEvent | TDatasetEvent) & {
  comment: string;
  message?: string; // TODO it is for old notifications, need to be removed later
};
export type TEvent = TDatastageEvent | TCDREvent;
export type TRawEvent = Record<string, any>;

type TStoreEvent = {
  event: 'datastage:data-store-event';
  data: TStoreEventDetails;
};

type TDeploymentEvent = {
  event: 'datastage:deployment-event';
  data: TDeploymentEventDetails;
};

type TDatasetEvent = {
  event: 'datastage:dataset-event';
  data: TDatasetEventDetails;
};

type TModelEvent = {
  event: 'datastage:data-model-event';
  data: TModelEventDetails;
};

type TExportEvent = {
  event: 'datastage:table-export-event';
  data: TExportEventDetails;
};

export type TStoreEventDetails = {
  action: 'data-store:file-errored' | 'data-store:file-processed' | 'data-store:table-deleted';
  id: number;
  study_id: number;
  error?: string;
  data: {
    id: number;
    name: number;
  };
};

export type TModelEventDetails = {
  action: 'data-model:run-finished';
  id: number;
  study_id: number;
  data: {
    id: number;
    name: string;
  };
};

type TExportEventDetails = {
  action: 'file-export:finished' | 'file-export:errored';
  file_path: string;
  study_id: number;
  study_name: string;
  error?: string;
  data: {
    file_name: string;
    table_name: string;
  };
};

type TDeploymentEventDetails = {
  action: 'deployment:finished' | 'deployment:errored';
  env: string;
  id: number;
  package_id: number;
  package_version: string;
  study_id: number;
  study_name: string;
};

type TDatasetEventDetails = {
  action: 'dataset:created' | 'dataset:updated' | 'dataset:deleted';
  name: string;
  scope: string;
  error?: string;
  successful: Record<number, string>;
  failed: Record<number, string>;
};

export enum CDRExtraTypes {
  ERROR_ALERT = 'error_alert',
}

export type TCDRExtraType = CDRExtraTypes | undefined;

export type TCDREvent = {
  link?: string;
  comment?: string;
  query_id?: number;
  report_export_id?: number;
  url?: string;
  reportName?: string;
  studyName?: string;
  user_role?: string;
  type?: TCDRExtraType;
  error_text?: string;
  error?: string;
  report_name?: string;
  scheduler_id?: string;
  download_urls?: string[];
  name?: string;
  text?: string;
  description?: string;
  source?: string;
  docId?: string;
  objType?: string;
  report_names?: string[];
  shared_rows_number: number;
};
