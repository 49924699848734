export enum ANALYSIS_PACKAGE_TAG_DESCRIPTION {
  'LIST' = 'ANALYSIS_PACKAGE_LIST',
  'ID' = 'ANALYSIS_PACKAGE_ID',
  'LIST_CDR_AP' = 'ANALYSIS_PACKAGE_LIST_CDR',
  'LIST_ENVS' = 'LIST_ENVS',
  'LIST_DEPLOYMENT' = 'LIST_DEPLOYMENT',
  'LIST_DEPLOYMENT_FILTERS' = 'LIST_DEPLOYMENT_FILTERS',
  'DEPLOYMENT_STATUSES' = 'DEPLOYMENT_STATUSES',
  'DEPLOYMENT' = 'DEPLOYMENT',
  'DEPLOYMENT_LOG_EXPORT' = 'DEPLOYMENT_LOG_EXPORT',
}

export enum AnalysisPackageDeploymentStatus {
  'Success' = 'Success',
  'Error' = 'Error',
  'Running' = 'Running',
}
