import { IAnalysisPackage } from '@modules/library/analysisPackage/AnalysisPackageTypes';
import { useAppPermissions, useStudyPermissions } from '@modules/user/duck/userHooks';
import { useDeployAnalysisPackageMutation } from '@modules/library/analysisPackage/duck/analysisPackageApi';
import { FormLayout, DraggableModal, Form, FormItem, Radio, notification, Skeleton, Alert, Typography } from '@ui';
import { selectAuthUser } from '@modules/auth/duck/AuthSelector';
import { ProdLabels, UatLabels } from '@modules/library/analysisPackage/modals';
import { filterEnvsQuery, sortingEnvs } from '@modules/library/analysisPackage/duck/analysisPackageUtils';
import { SupportedEnvs } from '@app/AppTypes';
import { useSupportedEnvsQuery } from '@app/duck/appApi';
import { selectGlobalStudy } from '@app/duck/appSelectors';
import { InformationModal } from '@components';
import { AntdIconBox } from '@components/icons';
import { isCrossStudy } from '@shared/utils/common';
import { QueryErrorType } from '@shared/utils/Error';
import { LogsInfo } from '@modules/library/analysisPackage/modals/components/deploymentLog/LogsInfo';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { CSSObject } from '@emotion/react';

const AnalysisPackageModalsDeployAnalysisPackageContent = ({
  data,
  onClose,
  t,
}: AnalysisPackageModalsDeployAnalysisPackageContentProps) => {
  const [form] = Form.useForm();
  const envsQuery = useSupportedEnvsQuery();
  const globalStudy = useSelector(selectGlobalStudy);
  const [deployAnalysisPackage, { isError, error }] = useDeployAnalysisPackageMutation();
  const [logDetails, setLogDetails] = useState('');

  const user = useSelector(selectAuthUser);
  const userCurrentEnv = user?.env_name || '';
  const allUserEnvs: SupportedEnvs = user?.environments || {};
  const currentEnvLabel = (allUserEnvs && allUserEnvs[userCurrentEnv]?.label) ?? '';

  const {
    userPermissions: { canGlDeploymentToProd, canGlDeploymentNotToProd },
  } = useStudyPermissions();

  const {
    appPermissions: { canCrossDeploymentNotToProd, canCrossDeploymentToProd },
  } = useAppPermissions();

  const errorMessage = (isError && error && 'data' in error && error.data.userMsg) || null;

  const clearLogDetails = () => setLogDetails('');

  const onSubmit = async (values: any) => {
    try {
      if (!values['env']) {
        return notification.error({
          message: t('deployForm.deploymentEnvsRequire'),
        });
      }
      await deployAnalysisPackage({
        env: values.env,
        packageId: data.id,
        studyName: globalStudy?.name!,
      }).unwrap();
      notification.info({
        message: t('deployForm.initiateDeploy', { name: data.version, env: envsQuery.data![values.env]!.label }),
      });
      onClose();
    } catch (error) {
      const { status, data: errorData } = error as QueryErrorType;
      if (status === 409) {
        return notification.warning({
          message: errorData.userMsg || t('deployForm.conflict'),
        });
      }
      return notification.error({
        message: errorData.userMsg || `Error while deploying analysis package version ${data?.version}`,
      });
    }
  };

  const envData = sortingEnvs(filterEnvsQuery(currentEnvLabel, envsQuery?.data));

  const filteredDeployedEnvList =
    data.deployed?.filter((el) => envsQuery?.data && Object.keys(envsQuery.data).includes(el.env)) ?? [];

  const getDeployedEnv = (envDataLabel: string) => filteredDeployedEnvList.find((el) => el.env === envDataLabel);

  const uatEnv = envData && envData.find((el) => UatLabels.includes(el[1]?.label ?? ''));
  const deployedEnvUAT = uatEnv && getDeployedEnv(uatEnv[0]);

  const isDisabledDeployButton =
    envsQuery?.data &&
    Object.keys(envsQuery.data)
      .map((envKey) => data.deployed?.find((dep: any) => dep.env === envKey))
      .every((deployment) => deployment?.success);

  return (
    <>
      <Typography.Text type="secondary" style={{ position: 'relative', top: '-16px' }}>
        {t('deployForm.description')}
      </Typography.Text>
      <FormLayout
        css={cssForm}
        form={form}
        onCancel={onClose}
        onSubmit={onSubmit}
        okText={t('deploy')}
        submitIsDisabled={isDisabledDeployButton}
        withActionDivider
      >
        {envsQuery.isLoading ? (
          <FormItem wrapperCol={{ span: 24 }}>
            <Skeleton active />
          </FormItem>
        ) : envData.length > 0 ? (
          <FormItem name="env" label={t('deployForm.envs')} rules={[{ required: true }]} colon>
            <Radio.Group css={cssEnvContainer}>
              {envData.map(([env, envDataDetails]) => {
                const deployedEnv = getDeployedEnv(env);
                const deployedWithError = !deployedEnv?.success;

                return (
                  <Radio
                    key={env}
                    value={env}
                    disabled={
                      !allUserEnvs[env] ||
                      (isCrossStudy(globalStudy?.id) && !canCrossDeploymentNotToProd) ||
                      (!isCrossStudy(globalStudy?.id) && !canGlDeploymentNotToProd) ||
                      (ProdLabels.includes(envDataDetails!.label) &&
                        !isCrossStudy(globalStudy?.id) &&
                        !canGlDeploymentToProd) ||
                      (ProdLabels.includes(envDataDetails!.label) &&
                        isCrossStudy(globalStudy?.id) &&
                        !canCrossDeploymentToProd) ||
                      (deployedEnv && deployedEnv.success) ||
                      (ProdLabels.includes(envDataDetails!.label) &&
                        uatEnv &&
                        (!deployedEnvUAT || !deployedEnvUAT.success))
                    }
                  >
                    <>
                      {`${envDataDetails!.label} (${envDataDetails!.domain})`}
                      {deployedEnv && (
                        <AntdIconBox
                          css={cssInfoIcon}
                          icon={deployedWithError ? QuestionCircleOutlined : InfoCircleOutlined}
                          tip={deployedWithError ? t('rootTable.tooltipError') : t('rootTable.tooltipInfo')}
                          color={deployedWithError ? 'danger' : undefined}
                          onClick={() => {
                            const uatNotDeployed = uatEnv && (!deployedEnvUAT || !deployedEnvUAT.success);
                            const errorMessage =
                              ProdLabels.includes(envDataDetails!.label) && uatNotDeployed
                                ? t('rootTable.noUatDeployment')
                                : deployedEnv?.log ?? t('rootTable.noLogDetails');
                            setLogDetails(errorMessage);
                          }}
                        />
                      )}
                      {!allUserEnvs[env] && <Typography.Text>{t('rootTable.noAccess')}</Typography.Text>}
                    </>
                  </Radio>
                );
              })}
            </Radio.Group>
          </FormItem>
        ) : (
          <FormItem wrapperCol={{ span: 24 }}>
            <Alert type="warning" message={t('deployForm.noDeploymentsFound')} />
          </FormItem>
        )}
        {errorMessage && (
          <FormItem wrapperCol={{ span: 24 }}>
            <Alert type="error" message={errorMessage} />
          </FormItem>
        )}
        {/* TODO Fix it when Deployment flow is clear */}
        {false && ProdLabels.includes(currentEnvLabel) && (
          <FormItem wrapperCol={{ span: 24 }}>
            <Alert
              css={cssProdWarning}
              type="error"
              message={
                <div style={{ whiteSpace: 'pre-line' }}>
                  <Typography.Title level={4} type="danger">
                    {t('deployForm.prodAttentionTitle')}
                  </Typography.Title>
                  <Typography.Text type="danger">
                    {t('deployForm.prodAttentionMessage', { prodLabel: currentEnvLabel })}
                  </Typography.Text>
                </div>
              }
            />
          </FormItem>
        )}
        {logDetails && (
          <InformationModal
            width="600px"
            autoHeightMin={200}
            title={t('details')}
            message={<LogsInfo logs={logDetails} />}
            onClose={clearLogDetails}
            hideInternalScroll
          />
        )}
      </FormLayout>
    </>
  );
};

export const AnalysisPackageModalsDeployAnalysisPackage = ({
  open,
  data,
  onClose,
}: AnalysisPackageModalsDeployAnalysisPackageProps) => {
  const { t } = useTranslation(['analysisPackage']);
  const isView = !!(data as IAnalysisPackage)?.id;

  return (
    <DraggableModal
      width="50%"
      open={open}
      onCancel={onClose}
      title={t('deployForm.title', { version: data?.version })}
      footer={null}
      destroyOnClose
    >
      {open && (
        <AnalysisPackageModalsDeployAnalysisPackageContent data={data} onClose={onClose} t={t} isView={isView} />
      )}
    </DraggableModal>
  );
};

const cssInfoIcon = (): CSSObject => ({
  marginLeft: 8,
});

const cssEnvContainer = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
});

const cssProdWarning = (): CSSObject => ({
  textAlign: 'center',
  border: '3px solid red',
  fontWeight: 600,
});

const cssForm = (): CSSObject => ({
  marginTop: 12,
  '&& .ant-form-item': {
    marginBottom: 60,
  },
});

export interface AnalysisPackageModalsDeployAnalysisPackageProps {
  open: boolean;
  data: Pick<IAnalysisPackage, 'id' | 'version' | 'description' | 'deployed'>;
  onClose: () => void;
}

interface AnalysisPackageModalsDeployAnalysisPackageContentProps
  extends Pick<AnalysisPackageModalsDeployAnalysisPackageProps, 'data' | 'onClose'> {
  t: TFunction;
  isView: boolean;
}
