import { Badge, Dropdown, Space } from '@ui';
import { selectTasksRunning } from '@modules/task/duck/tasksSelector';
import { TaskItem } from '@modules/task/components/TaskItem';
import { NoDataFound } from '@components';
import { TasksIcon } from '@components/icons';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const TasksButton = () => {
  const { t } = useTranslation(['shared']);

  const runningTasks = useSelector(selectTasksRunning);

  const renderMenu = useMemo(() => {
    return runningTasks.map((task) => ({
      key: task.id,
      label: <TaskItem task={task} />,
    }));
  }, [runningTasks]);

  const renderNoData = [
    {
      key: 'noData',
      label: (
        <Space css={cssNoDataContainer} align="center" justify="center">
          <NoDataFound title={t('task.noDataTitle')} description={t('task.noDataDescription')} />
        </Space>
      ),
    },
  ];

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      menu={{ items: !!runningTasks.length ? renderMenu : renderNoData }}
    >
      <Badge count={runningTasks.length} size="small" css={cssBadge(!!runningTasks.length)}>
        <TasksIcon css={cssTasksButton} />
      </Badge>
    </Dropdown>
  );
};

const cssBadge = (hasNotifications: boolean) => css`
  .ant-badge-count {
    height: 8px;
    min-width: 8px;
    top: 8px;
    right: 4px;
    display: ${hasNotifications ? 'block' : 'none'};

    .ant-scroll-number-only {
      display: none;
    }
  }
`;

const cssTasksButton = css`
  font-size: 32px;
`;

const cssNoDataContainer = () => css`
  width: 630px;
  min-height: 460px;
`;
