import AntdTag, { TagProps as AntdTagProps } from 'antd/es/tag';
import { ForwardedRef, forwardRef } from 'react';
import { CSSObject } from '@emotion/react';

const colorsMap: Record<string, string> = {
  blinded: 'green',
  filter: 'purple',
  snapshot: 'geekblue',
  source: 'cyan',
  review: 'magenta',
  running: 'processing',
  pending: 'cyan',
  partial: 'orange',
  success: 'success',
  error: 'error',
};

export const getTagColor = (color?: string) => {
  const preparedColor = color?.toLowerCase();
  if (preparedColor && colorsMap[preparedColor]) {
    return colorsMap[preparedColor];
  }
  return preparedColor ?? 'default';
};

export const Tag = forwardRef(({ type, color, text, upperCaseText, ...props }: TagProps, ref: ForwardedRef<any>) => {
  if (type) {
    return (
      <AntdTag
        {...props}
        children={text ? (upperCaseText ? text.toUpperCase() : text) : props.children}
        color={colorsMap[type]}
        ref={ref}
      />
    );
  }
  return <AntdTag css={cssTag} {...props} color={getTagColor(color)} ref={ref} />;
});

const cssTag = (): CSSObject => ({
  border: 'none',
  height: '32px',
  alignContent: 'center',
  padding: '5.5px 12px',
});

type TagType = 'filter' | 'source' | 'snapshot' | 'blinded' | 'review' | 'running' | 'partial';
type TagStatusType = AntdTagProps['color'] | 'running';

interface TagProps extends AntdTagProps {
  type?: TagType;
  color?: TagStatusType;
  text?: string;
  upperCaseText?: boolean;
}
