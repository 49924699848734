import { Button, Checkbox, Space, Typography } from '@ui';
import { Loader } from '@components';
import VirtualList from 'rc-virtual-list';
import { useTranslation } from 'react-i18next';
import { CSSObject, Theme } from '@emotion/react';
import { useCallback, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { NotificationItem } from './NotificationItem';
import {
  TNotificationId,
  useArchiveMutation,
  useReadMutation,
  useUnreadMutation,
  TNotification,
} from '../duck/notificationsApi';
import { fetchNotifications } from '../duck/notificationsListeners';

export enum EAction {
  read = 'read',
  unread = 'unread',
  archive = 'archive',
  nothing = '',
}

export const NotificationsActivePane = ({ notifications }: NotificationsActivePaneProps) => {
  const { t } = useTranslation(['shared']);
  const [read] = useReadMutation();
  const [unread] = useUnreadMutation();
  const [archive] = useArchiveMutation();
  const [selected, setSelected] = useState<TNotificationId[]>([]);
  const [processing, setProcessing] = useState<EAction>(EAction.nothing);

  const handleSelect = useCallback(
    (id: TNotificationId) =>
      setSelected((prev) => (prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id])),
    [setSelected],
  );

  const handleSelectAll = useCallback(
    (e: CheckboxChangeEvent) =>
      setSelected((prev) => (e.target.checked ? notifications.map((item) => item.notificationId) : [])),
    [setSelected, notifications],
  );

  const readBatch = (ids: number[]) => read(ids).unwrap();
  const unreadBatch = (ids: number[]) => unread(ids).unwrap();
  const archiveBatch = (ids: number[]) => archive(ids).unwrap();

  const hideActionButtons = !!processing || selected.length === 0;

  const processBatch = (action: EAction) => async () => {
    if (action === EAction.nothing || processing !== EAction.nothing || selected.length === 0) {
      return;
    }

    setProcessing(action);
    try {
      const processor = {
        [EAction.read]: readBatch,
        [EAction.unread]: unreadBatch,
        [EAction.archive]: archiveBatch,
      }[action];
      await processor(selected.map((item) => parseInt(item)));
      await fetchNotifications();
      setSelected([]);
    } catch (e) {
      console.error(e);
    } finally {
      setProcessing(EAction.nothing);
    }
  };

  return (
    <>
      {!hideActionButtons && (
        <Space css={cssActionButtonsContainer}>
          <Button size="large" onClick={processBatch(EAction.read)}>
            {t('notification.footer.markRead')}
          </Button>
          <Button size="large" onClick={processBatch(EAction.unread)}>
            {t('notification.footer.markUnread')}
          </Button>
          <Button danger size="large" onClick={processBatch(EAction.archive)}>
            {t('notification.footer.archive')}
          </Button>
        </Space>
      )}

      {notifications.length === 0 && (
        <div css={cssText}>
          <Typography.Text type="secondary">{t('notification.notificationsNotFound')}</Typography.Text>
        </div>
      )}
      <VirtualList data={notifications} height={450} itemHeight={100} itemKey="notificationId">
        {(notification) => (
          // div is really needed to avoid a blank extra spaces in the list during scrolling
          <div>
            <NotificationItem
              key={notification.notificationId}
              notification={notification}
              selected={selected.includes(notification.notificationId)}
              onSelect={handleSelect}
              selectable
            />
          </div>
        )}
      </VirtualList>

      <Space css={cssFooterContainer} direction="horizontal" full>
        <Space css={cssFooter}>
          <Checkbox
            checked={selected.length > 0 && selected.length === notifications.length}
            indeterminate={selected.length > 0 && selected.length !== notifications.length}
            disabled={!!processing || notifications.length === 0}
            onChange={handleSelectAll}
          >
            {t('notification.footer.selectAll')}
          </Checkbox>
        </Space>
      </Space>

      {processing && <Loader mode="absolute" />}
    </>
  );
};

const cssFooter = (): CSSObject => ({
  marginLeft: 16,
  marginRight: 16,
});

const cssFooterContainer = (): CSSObject => ({
  height: '64px',
  boxShadow: '0px -2px 6px 0px #00000014',
});

const cssText = (): CSSObject => ({
  width: '100%',
  textAlign: 'center',
});

const cssActionButtonsContainer = (theme: Theme): CSSObject => ({
  position: 'absolute',
  top: -54,
  left: 16,
  backgroundColor: 'white',
  height: 54,
  borderBottom: '2px solid #dde2e4',
});

interface NotificationsActivePaneProps {
  notifications: TNotification[];
}
